const videos = [
    {id: 3,vURL: "assets/img/marketing/reels/reel3.mp4"},
    {id: 4,vURL: "assets/img/marketing/reels/reel4.mp4"},
    {id: 5,vURL: "assets/img/marketing/reels/reel5.mp4"},
    {id: 6,vURL: "assets/img/marketing/reels/reel6.mp4"},
    {id: 8,vURL: "assets/img/marketing/reels/reel8.mp4"},
    {id: 9,vURL: "assets/img/marketing/reels/reel9.mp4"},
    {id: 10,vURL: "assets/img/marketing/reels/reel10.mp4"},
    {id: 13,vURL: "assets/img/marketing/reels/reel13.mp4"},
    {id: 14,vURL: "assets/img/marketing/reels/reel14.mp4"},
    {id: 15,vURL: "assets/img/marketing/reels/reel15.mp4"},
    {id: 16,vURL: "assets/img/marketing/reels/reel16.mp4"},
    {id: 18,vURL: "assets/img/marketing/reels/reel18.mp4"},
    {id: 19,vURL: "assets/img/marketing/reels/reel19.mp4"},
    {id: 20,vURL: "assets/img/marketing/reels/reel20.mp4"},
    {id: 21,vURL: "assets/img/marketing/reels/reel21.mp4"},
    {id: 22,vURL: "assets/img/marketing/reels/reel22.mp4"},
    {id: 23,vURL: "assets/img/marketing/reels/reel23.mp4"},
    {id: 24,vURL: "assets/img/marketing/reels/reel24.mp4"},
    {id: 25,vURL: "assets/img/marketing/reels/reel25.mp4"},
    {id: 27,vURL: "assets/img/marketing/reels/reel27.mp4"},
    {id: 28,vURL: "assets/img/marketing/reels/reel28.mp4"},
    {id: 29,vURL: "assets/img/marketing/reels/reel29.mp4"},
    {id: 31,vURL: "assets/img/marketing/reels/reel31.mp4"},
    {id: 32,vURL: "assets/img/marketing/reels/reel32.mp4"},
    {id: 33,vURL: "assets/img/marketing/reels/reel33.mp4"},
    {id: 35,vURL: "assets/img/marketing/reels/reel35.mp4"},
    {id: 37,vURL: "assets/img/marketing/reels/reel37.mp4"},
    {id: 39,vURL: "assets/img/marketing/reels/reel39.mp4"},
    {id: 40,vURL: "assets/img/marketing/reels/reel40.mp4"},
    {id: 41,vURL: "assets/img/marketing/reels/reel41.mp4"},
    {id: 42,vURL: "assets/img/marketing/reels/reel42.mp4"},
    {id: 43,vURL: "assets/img/marketing/reels/reel43.mp4"}
  ];
  
  export default videos;