import React from "react";
import Reels from "./Reels";
import proyecto from "../proyecto";

function createVideo(proyecto) {
    return (
      <Reels
        key={proyecto.id}
        vURL={proyecto.vURL}
      />
    );
  }

function Proyectos() {
    return (
        <section className="page-section bg-dark" id="proyectos">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-lg-12 text-center">
                    {proyecto.map(createVideo)}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Proyectos;