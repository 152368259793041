import React from "react";

function Header() {
    return(
        <header className="masthead" id="page-top">
            <div className="container px-4 px-lg-5 h-100">
                <div className="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
                <div className="col-lg-4 align-self-center">
                        <img style={{"max-width":"25vw", "padding-bottom": "15px"}} src="assets/img/logo.jpg" alt="logo"></img>
                    </div>
                    <div className="col-lg-8 align-self-center">
                        <h1 className="text-white font-weight-bold">EL LUGAR PARA RENTAR MAQUINARIA</h1>
                    </div>
                    <div className="col-lg-8 align-self-baseline"></div>
                </div>
            </div>
        </header>
    );
}

export default Header;