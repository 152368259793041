import React from "react";

function Machine(props) {
    return (
        <div className="col-lg-4 col-sm-6" style={{"border":"solid 10px "}}>
            <a className="portfolio-box" href="#portfolio" title={props.name}>
                <img className="img-fluid" src={props.imgURL} alt="..." />
                <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">{props.marca}</div>
                    <div className="project-name">{props.modelo}</div>
                </div>
            </a>
        </div>
    );
}

export default Machine;