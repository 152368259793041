const maquinaria = [
    {
      id: 1,
      name: "Camión de volteo",
      imgURL:
        "assets/img/portfolio/camion.jpg",
      marca: "Internacional",
      modelo: "WORKSTAR 7400-310"
    },
    {
        id: 2,
        name: "Cargador frontal",
        imgURL:
          "assets/img/portfolio/cargador_frontal.jpg",
        marca: "Caterpillar",
        modelo: "236D"
      },
      {
        id: 3,
        name: "Excavadora",
        imgURL:
          "assets/img/portfolio/excavadora.jpg",
        marca: "Caterpillar",
        modelo: "320D2"
      },
      {
        id: 4,
        name: "Grúa",
        imgURL:
          "assets/img/portfolio/grua.jpg",
        marca: "Grove",
        modelo: "RT530E"
      },
      {
        id: 5,
        name: "Hiab",
        imgURL:
          "assets/img/portfolio/hiab.jpg",
        marca: "Internacional",
        modelo: "WORKSTAR 7300-210"
      },
      {
        id: 6,
        name: "Retroexcavadora",
        imgURL:
          "assets/img/portfolio/retroexcavadora.jpg",
        marca: "Komatsu",
        modelo: "WB97R-5E0X"
      },
      {
        id: 7,
        name: "Rodillo doble",
        imgURL:
          "assets/img/portfolio/rodillo_doble.jpg",
        marca: "Cipsa",
        modelo: "AR-18 G"
      },
      {
        id: 8,
        name: "Rodillo liso",
        imgURL:
          "assets/img/portfolio/rodillo_liso.jpg",
        marca: "Caterpillar",
        modelo: "CS533E"
      },
      {
        id: 9,
        name: "Tractor sobre orugas",
        imgURL:
          "assets/img/portfolio/tractor_orugas.jpg",
        marca: "Caterpillar",
        modelo: "D6R"
      },
  ];
  
  export default maquinaria;