const fotos = [
    {id: 1,iURL: "assets/img/marketing/fotos/foto1.JPG"},
    {id: 2,iURL: "assets/img/marketing/fotos/foto2.png"},
    {id: 3,iURL: "assets/img/marketing/fotos/foto3.JPG"},
    {id: 4,iURL: "assets/img/marketing/fotos/foto4.JPG"},
    {id: 6,iURL: "assets/img/marketing/fotos/foto6.JPG"},
    {id: 7,iURL: "assets/img/marketing/fotos/foto7.JPG"},
    {id: 9,iURL: "assets/img/marketing/fotos/foto9.JPG"},
    {id: 10,iURL: "assets/img/marketing/fotos/foto10.JPG"},
    {id: 12,iURL: "assets/img/marketing/fotos/foto12.JPG"},
    {id: 13,iURL: "assets/img/marketing/fotos/foto13.JPG"},
    {id: 14,iURL: "assets/img/marketing/fotos/foto14.JPG"},
    {id: 15,iURL: "assets/img/marketing/fotos/foto16.JPG"},
    {id: 16,iURL: "assets/img/marketing/fotos/foto17.JPG"},
    {id: 17,iURL: "assets/img/marketing/fotos/foto18.JPG"},
    {id: 18,iURL: "assets/img/marketing/fotos/foto21.JPG"},
    {id: 19,iURL: "assets/img/marketing/fotos/foto22.JPG"}
  ];
  
  export default fotos;