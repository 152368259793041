import React from "react";
import Machine from "./Machine";
import maquinaria from "../maquinaria";

function createMachine(machine) {
    return (
      <Machine
        key={machine.id}
        name={machine.name}
        imgURL={machine.imgURL}
        marca={machine.marca}
        modelo={machine.modelo}
      />
    );
  }

function Portfolio() {
    return (
        <div id="portfolio">
            <div className="container-fluid p-0">
                <div className="row g-0">
                {maquinaria.map(createMachine)}
                </div>
            </div>
        </div>
    );
}

export default Portfolio;